@font-face {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Luckiest Guy";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/LuckiestGuy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Giant boss";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/giant-boss.ttf") format("truetype");
}
@font-face {
  font-family: "Pixel Digivolve";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Pixel-Digivolve.otf") format("truetype");
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: Luckiest Guy;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.center {
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
  align-items: center;
}

.mr-12 {
  margin-right: 12px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-4 {
  margin-right: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mt-32 {
  margin-top: 32px;
}

a {
  text-decoration: none;
}

h1 {
  font-family: Pixel Digivolve, sans-serif
}

.pointer {
  cursor: pointer;
}

.mxw-50 {
  max-width: 50%;
}

.flex-end {
  justify-content: end;
}

.page {
  width: 100vw;
  height: 100vh;
  color: #f9f0e7;
  background-image: url("../../public/background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.main {
  position: relative;
}

.mint-card.calculator {
  max-width: 50%;
}
.card-header {
  padding-top: 1rem;
}
.mint-item {
  justify-content: space-between;
  font-size: 18px;
}

.mint-item input {
  text-align: center;
  width: 50%;
  background: transparent;
  border: solid;
  border-color: #fff;
  color: #fff;
  border-radius: .5rem;
  padding: .2rem
}

.calculator-content{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calculator-warning{
  margin-top: 2.4rem;
  font-size: 14px;
}

.mint-item input,.mint-item p {
  display: flex;
  justify-content: left
}

.button-info{
  position: absolute;
  right: 7%;
  top: 4%;
}
.button-info.open-calculator{
  color: #ffb97e;
  display: flex;
  justify-content: center;
  align-items: center;
}
img.icon{
  width: 32px;
  color: #fff;
}
.button-info:hover{
  cursor: pointer;
}

.close-button{
  right: 5%;
  font-size: 32px;
}

.header {
  padding: 1rem;
  justify-content: space-between;
}

.name {
  font-family: "Giant boss";
  font-size: xx-large;
  margin-left: 16px;
}

.mint {
  display: flex;
  flex: 1;
  max-width: 50vw;
  height: 50vh;
  margin-top: 32px;
  padding: 1rem;
}

.mint-card {
  padding: 2rem;
  border: 2px solid #1f0930;
  border-radius: 24px;
  filter: drop-shadow(2px 4px 6px black);
  z-index: 9;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  font-family: "Space Grotesk";
  text-align: center;
  background: #5e00a4;
  min-width: 448px;
}

.mint-btn {
  padding: 1rem;
  width: 100%;
  border-radius: 12px;
  border: none;
  background: #ffb067;
  font-size: large;
  font-weight: 600;
  margin-top: 12px;
  cursor: pointer;
}

.font-large {
  font-size: xx-large;
  font-weight: 600;
}

.plus-minus-btn {
  background: black;
  color: #ffb067;
  padding: 0.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  cursor: pointer;
}

.cats {
  right: 0;
  bottom: 0;
  width: 45vw;
  position: absolute;
  display: flex;
  align-items: flex-end;
}

.cats.left-cats {
  left: -24px;
  bottom: 0;
  width: 35vw;
}

.cats > img {
  height: auto;
  width: 100%;
}

.footer {
  position: absolute;
  display: flex;
  align-items: flex-end;
  position: absolute;
  width: 100vw;
  height: 10vh;
  bottom: 0px;
  justify-content: center;
  align-items: flex-end;
  font-family: "Space Grotesk";
  color: #ffeaad;
  padding-bottom: 1rem;
}

.modal {
  width: 100vw;
  height: 100vh;
  background-color: #00000088;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10;
  left: 0;
}

.modal-div {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 24px;
  filter: drop-shadow(2px 4px 6px black);
  background: black;
  height: fit-content;
  width: 500px;
  font-family: "Space Grotesk";
  background-color: #1f0930;
}

.token {
  font-size: large;
  padding: 16px 0px;
  border-bottom: 1px solid #666;
}

.info {
  padding-top: 16px;
  color: #ffb067;
}

button:disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 1000px) {
  .footer {
    max-height: 15%;
  }

  .mint {
    max-width: 100vw;
    padding: 1rem;
    width: calc(100vw - 2rem);
  }
  img.icon{
    width: 26px;
    color: #fff;
  }
}

@media screen and (max-width: 700px) {
  .header > div {
    margin-bottom: 12px;
  }

  .mint-card {
    min-width: inherit;
  }

  .modal {
    margin-left: -1rem;
  }

  .mint-item input {
    width: 40%;
  }

  .mint-card.calculator {
    max-width: 100vw;
  }
}

@media screen and (max-width: 500px) {
  .cats {
    width: 50vw;
  }

  .cats.left-cats{
    left: 0;
  }

  .mint-card.calculator {
    font-size: 14px;
    padding: 1rem;
  }
}

@media screen and (max-width: 400px){
  .mint {
    margin-top: 0;
    font-size: 14px;
    /*padding: 0.6rem 1rem;*/
  }

  .header.flex {
    padding: 0;
    justify-content: center;
  }

  .footer {
    max-height: 12%;
    padding-bottom: 0.4rem;
  }

  .cats {
    width: 45vw;
  }

  .mint-item {
    font-size: 16px;
  }
}